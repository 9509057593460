#main-container {
    min-height: 100vh;
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 10rem;
    margin: 0 !important;
}

#header {
    padding: 0;
}

#footer {
    position: absolute;
    padding: 0;
    bottom: 0;
    width: 100%;
    height: 10rem;
    text-align: center;
}

#content {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
#content-mobile {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

@media (min-width: 768px) {
    #content {
        padding: 0rem;
    }
}
