.container {
    height: 550px;
    width: 600px;
    background-color: rgba(212, 212, 212, 0.3);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 5;
    margin: auto;
    position: relative;
}

.descrip {
    position: relative;
    left: 70px;
}

.cross {
    position: absolute;
    top: 0px;
    margin-right: 100px;
    cursor: pointer;
    display: inline-block !important;
}

.img-wrap {
    height: 400px;
    width: 300px;
    object-fit: cover;
}

.img-container {
    margin-top: 30px;
    margin-left: 10%;
}
