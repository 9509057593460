.dropdown, .datePicker{
  margin-bottom: 20px;
}

.dropdown2 {
  margin-top: 20px;
}

.btnContainer {
  margin-bottom: 220px;
}

.button {
  margin-left: 20px !important;
}

.documentContainer {
  margin-top: 10px;
}

.documentContainer, .errorContainer {
  margin-top: -200px;
  margin-bottom: 375px;
}

.initialClickNoFilter {
  margin-top: -200px;
  margin-bottom: 205px;
}

.pMonHeader {
  margin-top: 10px;
  margin-bottom: 15px;
}

.previewContainer {
  margin-top: 30px;
}

.selectFilterDropdown {
  position: relative;
  bottom: 200px;
}

.cantDownload {
  position: relative;
  bottom: 210px;
}
